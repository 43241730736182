<script lang="ts">
import { defineComponent } from 'vue'
import { useSystemStore } from '@/stores/system'
import { getContrastColor } from '@/util/colors'
import { useSystemColor } from '@/composables/useSystemColor'

export default defineComponent({
  name: 'system-tag',
  props: {
    systemId: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      systemStore: useSystemStore(),
      useSystemColor
    }
  },
  computed: {
    displayName() {
      return this.systemStore.findById(this.systemId)?.name || this.systemId.split('-')[0]
    },
    colorBackground() {
      return this.useSystemColor(this.systemId)
    },
    colorText() {
      return getContrastColor(this.colorBackground)
    },
    style() {
      return {
        color: this.colorText
      }
    }
  }
})
</script>

<template>
  <!-- use :color instead of style attribute because of cypress transition-stub wrapper... -->
  <el-tag class="system-tag" :color="colorBackground" disable-transitions>
    <span :style="style">
      {{ displayName }}
    </span>
  </el-tag>
</template>

<style scoped lang="css">
.system-tag {
  @apply border border-gray-200 !important;
}
</style>
