import { CalculationResult, CalculationStatus, CalculationStatusStatus } from '@gridside/hsb-api'

export type { CalculationResult, CalculationStatus }

export type CalculationStatusValue = CalculationStatusStatus

export enum HsbLibObjectEnum {
  MESHED_MEDIUM_SIMULATION = 'MESHED_MEDIUM_SIMULATION',
  OVERHEADLINE = 'OVERHEADLINE'
}
