import { OperationalModeId, ProjectId } from '@/model'
import { OperationalMode, OperationalModesApi } from '@gridside/hsb-api'

/**
 * API class that extends the generated REST API with custom functions
 */
export class OperationalModesExtendedApi extends OperationalModesApi {
  async saveOperationalMode(projectId: ProjectId, id: OperationalModeId, item: OperationalMode) {
    // Add frequency to each conductorState
    const frequency = item.frequency
    const fixedConductorStates: any = {}
    Object.keys(item.conductorStates).forEach((systemId) => {
      fixedConductorStates[systemId] = item.conductorStates[systemId].map((item) => ({
        ...item,
        frequency
      }))
    })

    const requestItem: OperationalMode = { ...item, conductorStates: fixedConductorStates }

    return super.saveOperationalMode(projectId, id, requestItem)
  }
}
