<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import MenuSection from '@/components/navigation/MenuSection.vue'
import MenuItem from '@/components/navigation/MenuItem.vue'
import { useProjectStore } from '@/stores/project'
import { Project } from '@/model'
import { useProject } from '@/composables/useProject'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { RouteParams } from '@/router/routeParams'

export default defineComponent({
  name: 'ProjectNavigation',
  components: {
    // ProjectImportSourcesDialog,
    MenuItem,
    MenuSection
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    }
  },
  setup() {
    const projectStore = useProjectStore()
    const { projectId } = useProject()
    projectStore.ensureLoaded()
    const overheadLineStore = useOverheadLineStore()
    // overheadLineStore.ensureLoaded(projectId.value)

    // const systemStore = useSystemStore()
    // const conductorAllocationStore = useConductorAllocationStore()
    // const towerStore = useTowerStore()
    // const spanStore = useSpanStore()
    // const operationalModeStore = useOperationalModeStore()

    // systemStore.ensureLoaded(projectId.value)
    // conductorAllocationStore.ensureLoaded(projectId.value)
    // towerStore.ensureLoaded(projectId.value)
    // spanStore.ensureLoaded(projectId.value)
    // operationalModeStore.ensureLoaded(projectId.value)

    // const importSourceDisabled = computed(() => {
    //   const projectSourceItemsCount =
    //     systemStore.items.length +
    //     conductorAllocationStore.items.length +
    //     towerStore.items.length +
    //     spanStore.items.length +
    //     operationalModeStore.items.length
    //   return projectSourceItemsCount !== 0
    // })

    const unlocking = ref(false)
    return {
      projectStore,
      unlocking,
      RouteParams,
      overheadLineStore,
      // importSourceDisabled,
      projectId
    }
  },
  data() {
    return {
      tooltip: false
      // importDialog: false
    }
  },
  computed: {
    showOverheadLinesEntries() {
      return this.$route.matched.some(
        (route) => typeof route.name === 'string' && route.name.includes('overhead-line')
      )
    }
  },

  methods: {
    async closeProject() {
      if (!this.projectId) {
        return
      }

      this.unlocking = true
      try {
        await this.projectStore.unlock(this.projectId)
        await this.$router.push({ name: 'projects' })
      } finally {
        this.unlocking = false
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col h-full">
    <div class="px-8 text-lg pt-6 pb-4 border-b border-gray-300">
      <div class="overflow-hidden">
        <div class="font-semibold">{{ project.name }}</div>
        <div class="font-normal opacity-60 text-base">{{ project.customer }}</div>
      </div>
    </div>
    <div class="py-6 px-6 flex-1">
      <MenuSection>
        <MenuItem :to="{ name: 'project-edit' }">Projektdaten</MenuItem>
        <MenuItem :to="{ name: 'project-map' }">Kartenansicht</MenuItem>
      </MenuSection>

      <MenuSection title="Quelle">
        <MenuItem :to="{ name: 'project-systems' }">Systeme</MenuItem>
        <MenuItem :to="{ name: 'project-conductor-allocations' }">Leiterzuordnungen</MenuItem>
        <MenuItem :to="{ name: 'overhead-lines-index' }">
          Freileitungen
          <template #nested>
            <el-collapse-transition>
              <div v-show="showOverheadLinesEntries">
                <MenuItem
                  v-for="item in overheadLineStore.items"
                  :key="item.overheadLine.id"
                  class="text-sm"
                  :to="{
                    name: 'overhead-line',
                    params: { [RouteParams.OverheadLineId]: item.overheadLine.id }
                  }"
                >
                  {{ item.overheadLine.name || item.overheadLine.id }}
                </MenuItem>
              </div>
            </el-collapse-transition>
          </template>
        </MenuItem>
      </MenuSection>

      <MenuSection title="Medien">
        <MenuItem :to="{ name: 'project-media-group' }">Mediengruppen</MenuItem>
      </MenuSection>

      <MenuSection title="Berechnung">
        <MenuItem :to="{ name: 'project-operational-modes' }">Betriebsfälle</MenuItem>
      </MenuSection>

      <MenuSection title="Projektbibliothek">
        <MenuItem :to="{ name: 'project-towertypes' }">Masttypen</MenuItem>
        <MenuItem :to="{ name: 'project-conductortypes' }">Leitertypen</MenuItem>
        <MenuItem :to="{ name: 'project-pipetypes' }">Rohrleitungstypen</MenuItem>
      </MenuSection>
    </div>
    <div class="pb-6 px-6">
      <p-btn
        class="w-full"
        @click="closeProject"
        title="Hebt die Sperrung dieses Projektes auf, damit andere Nutzer es bearbeiten können."
        :loading="unlocking"
        data-test="close-project"
      >
        Projekt schließen
      </p-btn>
    </div>
    <!--    <ProjectImportSourcesDialog-->
    <!--      v-model="importDialog"-->
    <!--      :disabled="importSourceDisabled"-->
    <!--    ></ProjectImportSourcesDialog>-->
  </div>
</template>

<style scoped lang="css"></style>
