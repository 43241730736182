<script lang="ts">
import OverheadLineImportHint from '@/components/overhead-line/OverheadLineImportHint.vue'
import { defineComponent, ref } from 'vue'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { RouteParams } from '@/router/routeParams'
import useSearch from '@/composables/useSearch'
import CenteredPage from '@/layouts/pages/CenteredPage.vue'
import TableActionMenu from '@/components/common/TableActionMenu.vue'
import { OverheadLine } from '@gridside/hsb-api'
import { storeToRefs } from 'pinia'
import { ElButton } from 'element-plus'
import { useSystemStore } from '@/stores/system'
import { useProject } from '@/composables/useProject'
import SystemTag from '@/components/system/SystemTag.vue'

export default defineComponent({
  name: 'OverheadLineListView',

  components: { OverheadLineImportHint, SystemTag, TableActionMenu, CenteredPage },
  data() {
    return {
      itemToDelete: null as OverheadLine | null
    }
  },
  setup() {
    const importButton = ref<InstanceType<typeof ElButton> | null>(null)

    // setup
    const { projectId } = useProject()
    const overheadLineStore = useOverheadLineStore()
    const systemStore = useSystemStore()
    systemStore.ensureLoaded(projectId.value)

    // search
    const search = ref('')
    const searchComposable = useSearch(storeToRefs(overheadLineStore).items, search)

    return {
      search,
      searchComposable,
      overheadLineStore,
      RouteParams,
      importButton,
      systemStore
    }
  },
  methods: {
    deleteOverheadLine() {
      if (this.itemToDelete) {
        this.overheadLineStore.delete(this.itemToDelete.id)
      }
    },
    importGeoJson() {
      this.$router.push({ name: 'overhead-line-import-geo-json' })
    },
    importOverheadLineJson() {
      this.$router.push({ name: 'overhead-line-import-hsb' })
    }
  }
})
</script>

<template>
  <CenteredPage title="Freileitungen">
    <!-- Top Bar -->
    <template #top-right>
      <div class="flex space-x-4 items-center">
        <!-- Slot -->
        <slot name="extra-tools"></slot>

        <!--Import Button -->
        <el-dropdown trigger="click">
          <el-button ref="importButton">
            Import
            <el-icon class="el-icon--right"><ChevronDownIcon /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click="importOverheadLineJson"
                data-test="import-from-overheadline"
              >
                aus HSBlib-Overheadline-Datei...
              </el-dropdown-item>
              <el-dropdown-item @click="importGeoJson" data-test="import-from-geojson">
                aus GeoJSON-Datei...
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <!--Search -->
        <el-input
          v-model="search"
          style="width: 30ch"
          placeholder="Suche"
          prefix-icon="SearchIcon"
        />
      </div>
    </template>

    <!-- Content -->
    <template #default>
      <template v-if="overheadLineStore.items.length > 0">
        <el-row
          v-for="item in searchComposable.searchResult.value"
          :key="item.overheadLine.id"
          class="mb-5"
        >
          <el-col>
            <router-link
              :to="{
                name: 'overhead-line',
                params: { [RouteParams.OverheadLineId]: item.overheadLine.id }
              }"
            >
              <el-card shadow="hover">
                <div class="flex flex-row">
                  <!-- Details -->
                  <div class="flex-1 flex flex-col gap-2">
                    <h2 class="text-lg font-semibold">{{ item.overheadLine.name }}</h2>
                    <div class="flex text-gray-500">
                      <div class="w-24">{{ item.overheadLine.towerCount }} Masten</div>
                      <div>
                        {{
                          item.overheadLine.length == null
                            ? '-'
                            : $n(item.overheadLine.length, { maximumFractionDigits: 1 })
                        }}
                        km
                      </div>
                    </div>
                    <span class="text-gray-400">
                      {{ item.overheadLine.description || '&nbsp;' }}
                    </span>
                  </div>

                  <!-- System Tags -->
                  <div class="flex-1 flex gap-2 flex-wrap content-start">
                    <template v-for="systemId in item.overheadLine.systemsUsed">
                      <SystemTag v-if="systemId" :system-id="systemId" :key="systemId" />
                    </template>
                  </div>

                  <!-- Edit/Delete -->
                  <div class="flex items-center ml-8">
                    <TableActionMenu
                      :items="[
                        {
                          label: 'bearbeiten',
                          icon: 'EditIcon',
                          action: () =>
                            $router.push({
                              name: 'overhead-line-edit',
                              params: {
                                [RouteParams.ProjectId]: item.overheadLine.project,
                                [RouteParams.OverheadLineId]: item.overheadLine.id
                              }
                            })
                        },
                        {
                          label: 'löschen...',
                          icon: 'DeleteIcon',
                          class: 'text-danger-500',
                          action: () => (itemToDelete = item.overheadLine)
                        }
                      ]"
                    />
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
        </el-row>
        <OverheadLineImportHint
          class="mt-4 text-center text-gray-500"
          @import="importButton?.$el.click()"
        />
      </template>
      <div
        v-else
        class="border-2 border-dashed rounded-lg py-24 px-[20%] text-center text-lg font-medium text-gray-400 flex flex-col items-center"
      >
        <p class="text-gray-500 text-xl">Sie haben bisher keine Freileitung angelegt.</p>
        <OverheadLineImportHint
          class="mt-8 mb-12 text-gray-400 font-normal text-lg leading-relaxed max-w-[62ch]"
          @import="importButton?.$el.click()"
        />
      </div>
    </template>
  </CenteredPage>

  <p-dialog
    confirmLabel="Löschen"
    danger
    :show="!!itemToDelete"
    @update:show="itemToDelete = null"
    @confirm="deleteOverheadLine"
    title="Freileitung löschen"
  >
    <slot name="confirm-delete" :item="itemToDelete" v-if="itemToDelete">
      Sind Sie sicher, dass Sie
      <b v-if="itemToDelete.name">{{ itemToDelete.name }}</b>
      <template v-else>den Eintrag</template>
      löschen möchten?
    </slot>
  </p-dialog>
</template>

<style scoped lang="css">
.overhead-line-card {
  grid-template-areas: 'h2 h2 ';
}
</style>
