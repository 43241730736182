import { useSystemColor } from '@/composables/useSystemColor'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ProjectId, System, SystemId } from '@/model'
import { HsbApi } from '@/api'

const SystemsApi = HsbApi.systems

export const useSystemStore = defineStore('system', {
  state: () => ({
    itemsById: {} as Record<SystemId, System>,
    loaded: false,
    loadedProject: undefined as ProjectId | undefined,
    loading: false,
    selection: [] as SystemId[]
  }),

  getters: {
    findById(state) {
      return (id: SystemId): System | undefined => state.itemsById[id]
    },
    items(): System[] {
      return Object.values(this.itemsById)
    }
  },

  actions: {
    async delete(id: SystemId) {
      const system = this.findById(id)
      if (system) {
        await SystemsApi.deleteSystem(system.project, system.id)
        delete this.itemsById[id]
      }
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    async load(projectId: ProjectId) {
      this.loading = true
      this.itemsById = {}
      const itemsById: Record<SystemId, System> = {}

      try {
        if (!projectId) {
          throw new Error('SystemStore.load: ProjectId must not be empty.')
        }

        const systems = (await SystemsApi.getSystems(projectId)).results
        for (const system of systems) {
          if (!system.color) {
            // system has no color (maybe legacy data), assign one
            system.color = useSystemColor(system.id)
            this.save(system)
          }
          itemsById[system.id] = system
        }
        this.itemsById = { ...itemsById }
        this.loaded = true
        this.loadedProject = projectId
      } finally {
        this.loading = false
      }
    },

    async save(item: System) {
      let requestItem: System
      if (!item.id) {
        requestItem = { ...item, id: uuid() }
      } else {
        requestItem = item
      }
      const updatedItem = await SystemsApi.saveSystem(
        requestItem.project,
        requestItem.id,
        requestItem
      )
      this.itemsById = { ...this.itemsById, [requestItem.id]: updatedItem }
      return updatedItem
    }
  }
})
