import { SystemId } from '@/model'
import { useSystemStore } from '@/stores/system'
import { randomColor } from '@/util/colors'
import { ref } from 'vue'

/**
 * Set of the first couple of colors
 */
export const predefinedColors = ref([
  '#22b1a5',
  '#b6b612',
  '#a54acb',
  '#c5335f',
  '#c58d33',
  '#3292c5',
  '#62c522'
])

/**
 * Map for all colors assigned to systems
 * Important for the case when default colors are exceeded, and
 * we want to display a color for a system unified across the app
 */
const systemColorPalette: Record<SystemId, string> = {}

export function useSystemColor(systemId: SystemId) {
  // is the color for the systems already cached?
  if (systemColorPalette[systemId]) {
    return systemColorPalette[systemId]
  }

  // if the system already has a color, use this
  const systemStore = useSystemStore()
  const system = systemStore.findById(systemId)
  if (system && system.color) {
    systemColorPalette[systemId] = system.color
    return system.color
  }

  // if nothing found, assign new color
  const color = predefinedColors.value.pop() ?? randomColor()
  systemColorPalette[systemId] = color
  return color
}
