<script lang="ts">
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { OperationalMode, OperationalModeId, ProjectId, SystemId } from '@/model'
import { useSystemStore } from '@/stores/system'
import { useDuplicate } from '@/composables/crud-helpers/useDuplicate'
import { useDelete } from '@/composables/crud-helpers/useDelete'
import { useRoute } from 'vue-router'
import { RouteParams } from '@/router/routeParams'

export default defineComponent({
  name: 'OperationalModeListView',
  components: { EntityTableView },

  setup() {
    const route = useRoute()
    const operationalModeStore = useOperationalModeStore()
    const systemStore = useSystemStore()

    const { duplicateItems } = useDuplicate(operationalModeStore.save)
    const { deleteItems } = useDelete(operationalModeStore.delete, {
      check: (items: OperationalMode[]) => items.some((item) => item.id === route.params.id),
      routeName: 'project-operational-modes'
    })

    return { operationalModeStore, systemStore, duplicateItems, deleteItems }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    searchStrings(): string[] {
      return this.operationalModeStore.items.map((item) => item.name)
    },
    operationalModeId(): OperationalModeId {
      return this.$route.params[RouteParams.OperationalModeId] as string
    }
  },

  watch: {
    operationalModeId() {
      this.operationalModeStore.selection = this.operationalModeId ? [this.operationalModeId] : []
    }
  },

  mounted() {
    this.operationalModeStore.load(this.projectId)
    this.operationalModeStore.selection = this.operationalModeId ? [this.operationalModeId] : []
    this.systemStore.ensureLoaded(this.projectId)
  },

  methods: {
    formatSystemName(systemId: SystemId): string {
      return this.systemStore.findById(systemId)?.name || ''
    },

    async onCreate() {
      this.operationalModeStore.selection = []
      this.$router.push({ name: 'project-operational-mode-create' })
    },

    asOpMode(row: any): OperationalMode {
      return row
    },
    efString(opMode: OperationalMode): string {
      return opMode.expectationFactor ? this.$n(opMode.expectationFactor) : '-'
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="operationalModeStore.selection"
    :allow-edit="false"
    allow-duplicate
    create-label="Neuer Betriebsfall"
    confirm-delete-label="Betriebsfall löschen"
    default-sort="name"
    :items="operationalModeStore.items"
    :loading="operationalModeStore.loading"
    :search-strings="searchStrings"
    title="Betriebsfälle"
    @create="onCreate"
    @edit="$router.push({ name: 'project-operational-mode-edit', params: { id: $event } })"
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
  >
    <template #columns>
      <!-- Name -->
      <el-table-column prop="name" label="Name" sortable class-name="font-semibold" />

      <!-- Typ -->
      <el-table-column prop="type" label="Typ" sortable width="200">
        <template #default="{ row }">
          <span v-if="asOpMode(row).type">
            {{ $t(`operationalMode.type.${asOpMode(row).type}`) }}
          </span>
          <template v-if="asOpMode(row).type === 'failure'">
            <span title="Erwartungsfaktor">&nbsp;(EF {{ efString(asOpMode(row)) }})</span>
          </template>
        </template>
      </el-table-column>

      <!-- Frequenz -->
      <el-table-column prop="type" label="Frequenz" sortable width="150">
        <template #default="{ row }">
          <span v-if="asOpMode(row).frequency">{{ $n(asOpMode(row).frequency) }} Hz</span>
        </template>
      </el-table-column>

      <!-- Grenzwert -->
      <el-table-column prop="limitVoltage" label="Grenzwert" sortable width="150">
        <template #default="{ row }">
          <span v-if="asOpMode(row).limitVoltage">{{ $n(asOpMode(row).limitVoltage) }} V</span>
        </template>
      </el-table-column>

      <!-- Betriebszustand -->
      <el-table-column prop="offset" label="Betriebszustand" sortable>
        <template v-slot:default="{ row }">
          <div
            class="grid"
            :style="`grid-template-columns: repeat(${
              Object.keys(asOpMode(row).conductorStates).length
            }, 1fr)`"
          >
            <div
              v-for="systemId in Object.keys(asOpMode(row).conductorStates)"
              :key="systemId"
              class="text-xs"
            >
              <div class="font-medium">{{ formatSystemName(systemId) }}</div>
              <div v-for="n in asOpMode(row).conductorStates[systemId].length" :key="n">
                L{{ n }}: {{ $n(asOpMode(row).conductorStates[systemId][n - 1].current || 0) }} A /
                {{ $n(asOpMode(row).conductorStates[systemId][n - 1].angle || 0) }} °
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </template>

    <!-- Empty hint -->
    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Betriebsfälle angelegt.</p>
      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed"></p>
      <p-btn @click="onCreate">Neuen Betriebsfall anlegen</p-btn>
    </template>

    <!-- Delete -->
    <template #confirm-delete="{ items }">
      <div v-if="items.length === 1">
        Wollen Sie den Betriebsfall
        <b>{{ asOpMode(items[0]).name }}</b>
        wirklich löschen?
      </div>
      <div v-else>
        Wollen Sie diese Betriebsfälle wirklich löschen?
        <ul>
          <li v-for="item in items" :key="item.id">
            <b>{{ asOpMode(item).name }}</b>
          </li>
        </ul>
      </div>
    </template>
  </EntityTableView>
</template>
