<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ZodError } from 'zod'
import FileInputResettable from '@/components/common/FileInputResettable.vue'
import useFileReader from '@/composables/useFileReader'
import RequestsProgress from '@/components/common/RequestsProgress.vue'
import {
  HSBLibModels,
  HSBLibModelsSchema,
  toHSBGuiModels
} from '@/config/schemas/hsb-lib/data-serialized'
import { HSBLibGlobals, HSBLibGlobalsSchema } from '@/config/schemas/hsb-lib/globals'
import { useProject } from '@/composables/useProject'
import ProjectImportErrorTabs from '@/components/project/import/ProjectImportErrorTabs.vue'
import { ConductorType, fromHSBLibGlobals, Project, TowerType } from '@/model'
import { project } from '@/config/fields'
import ProjectHSBLibParameterFieldRow from '@/components/project/ProjectHSBLibParameterFieldRow.vue'
import { useProgress } from '@/composables/useProgress'
import { useProjectStore } from '@/stores/project'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useSystemStore } from '@/stores/system'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useTowerTypeStore } from '@/stores/tower-type'
import { HSBGuiItemsEnum } from '@/config/schemas/hsb-lib/util'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { TextFieldConfig } from '@prionect/ui'
import { ProjectionTypesEnum } from '@/config/projections'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { findUniqueCopyName, values } from '@/util/helpers'
import { ConductorAllocation, HsbLibParameters, System } from '@gridside/hsb-api'
import { FieldConfigHsbLibParameters } from '@/config/fields/project'

type ValidatedHSBData = {
  crs: ProjectionTypesEnum | string
  hsbLibParameter: HsbLibParameters
  models: ReturnType<typeof toHSBGuiModels>
}

export default defineComponent({
  name: 'OverheadLineImportHSBDialog',
  components: {
    ProjectHSBLibParameterFieldRow,
    ProjectImportErrorTabs,
    RequestsProgress,
    FileInputResettable
  },
  props: {
    /**
     * Dialog visibility
     */
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const progress = useProgress()
    const project = useProject()
    const { overheadLineStore } = useOverheadLine()
    const stores = {
      project: useProjectStore(),
      operationalMode: useOperationalModeStore(),
      [HSBGuiItemsEnum.conductorTypes]: useConductorTypeStore(),
      [HSBGuiItemsEnum.systems]: useSystemStore(),
      [HSBGuiItemsEnum.allocations]: useConductorAllocationStore(),
      [HSBGuiItemsEnum.towerTypes]: useTowerTypeStore(),
      [HSBGuiItemsEnum.towers]: overheadLineStore,
      [HSBGuiItemsEnum.spans]: overheadLineStore,
      [HSBGuiItemsEnum.overheadLine]: overheadLineStore
    }
    const progresses = {
      [HSBGuiItemsEnum.systems]: useProgress(),
      [HSBGuiItemsEnum.allocations]: useProgress(),
      [HSBGuiItemsEnum.towers]: useProgress(),
      [HSBGuiItemsEnum.towerTypes]: useProgress(),
      [HSBGuiItemsEnum.spans]: useProgress(),
      [HSBGuiItemsEnum.conductorTypes]: useProgress(),
      [HSBGuiItemsEnum.overheadLine]: useProgress(),
      hsbLibParameters: useProgress(),
      operationalMode: useProgress()
    }

    const dataParsed = ref<null | { globals: HSBLibGlobals; data: HSBLibModels }>(null)
    const dataValidated = ref<null | ValidatedHSBData>(null)
    const errors = ref<(string | Error | ZodError)[]>([])

    const systemNameSeparator = ref('_')
    /**
     * File reader bound function to read project import from file
     */
    const handleFileData = (data: string | ArrayBuffer | null, file: File) => {
      reset()
      try {
        const fileJsonContent: object | any = JSON.parse(data as string)

        // Prevent iterating on null
        if (typeof fileJsonContent !== 'object') {
          errors.value.push('Could not parse JSON: Result is not an object')
          return
        }
        const { globals, ...rest } = fileJsonContent
        if (!globals || !rest) {
          errors.value.push('Daten unvollständig! "globals" oder andere Daten fehlen!')
          return
        }

        // validation
        const hsbGlobals = HSBLibGlobalsSchema.parse(globals)
        const hsbObjects = HSBLibModelsSchema.parse(rest)
        dataParsed.value = { globals: hsbGlobals, data: hsbObjects }

        // transformation
        const hsbLibParameter = fromHSBLibGlobals(hsbGlobals)
        const hsbDataValidated = toHSBGuiModels(
          hsbObjects,
          systemNameSeparator.value,
          project.projectId.value,
          file
        )

        dataValidated.value = {
          crs: hsbGlobals.CRS_system,
          hsbLibParameter,
          models: hsbDataValidated
        }
      } catch (e) {
        errors.value.push(e as Error)
      }
    }

    /**
     * Reset errors and parse data
     */
    function reset() {
      Object.values(progresses).forEach((item) => item.reset())
      dataParsed.value = null
      dataValidated.value = null
      errors.value = []
      progress.reset()
    }

    return {
      errors,
      reset,
      dataParsed,
      dataValidated,
      useFileReader: useFileReader(handleFileData, errors),
      progress,
      progresses,
      systemNameSeparator,
      stores,
      project,
      overheadLineStore
    }
  },
  data() {
    return {
      fieldConfig: project,
      fieldConfigHsbLibParameters: FieldConfigHsbLibParameters,
      separatorConfig: {
        label: '"Current"-Trennzeichen für die Erstellung der Systeme:',
        hint: 'Systeme werden aus den Namen der Current-Objekte in der OverheadLine-Datei abgeleitet. So wird aus dem Current "1_0" der Strom auf dem ersten Leiter des Systems "1".',
        type: 'text',
        required: true,
        name: 'separator'
      } as TextFieldConfig
    }
  },
  emits: ['update:model-value'],
  computed: {
    hsbLibSettingFields() {
      return [
        {
          initialValue: this.dataValidated?.hsbLibParameter.corridorFlatend,
          config: this.fieldConfig.corridorFlatend
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.corridorFlatstart,
          config: this.fieldConfig.corridorFlatstart
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.corridorMode,
          config: this.fieldConfig.corridorMode
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.loggingTraceback,
          config: this.fieldConfig.loggingTraceback
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.logLevel,
          config: this.fieldConfig.loggingTraceback
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.flagInnerImpedanceBesselFunction,
          config: this.fieldConfig.flagInnerImpedanceBesselFunction
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.flagDebugging,
          config: this.fieldConfig.flagDebugging
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.pipelineSegmentation,
          config: this.fieldConfig.pipelineSegmentation
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.relAbstol,
          config: this.fieldConfig.relAbstol
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.relAngletol,
          config: this.fieldConfig.relAngletol
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.relMaxAngle,
          config: this.fieldConfig.relMaxAngle
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.relMinSegmentLength,
          config: this.fieldConfig.relMinSegmentLength
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.relSimplifyMedium,
          config: this.fieldConfig.relSimplifyMedium
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.trimTillDecimalPoints,
          config: this.fieldConfig.trimTillDecimalPoints
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.utmReimportTolerance,
          config: this.fieldConfig.utmReimportTolerance
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.utmTrimMode,
          config: this.fieldConfig.utmTrimMode
        }
      ]
    }
  },
  methods: {
    doClose() {
      this.reset()
      this.$emit('update:model-value', false)
    },

    onFileChange(file: File) {
      this.reset()
      if (file) {
        this.useFileReader.onFileChange(file)
      }
    },

    /**
     * Send parsed data to backend
     */
    async handleImport() {
      this.errors = []
      if (!this.dataValidated || !this.project.project.value) {
        return
      }
      await this.doRequests(this.dataValidated, this.project.project.value)
      if (!this.progress.errors.length || !this.errors.length) {
        return
        // return this.doClose()
      }
      this.errors.push(...this.progress.errors)
    },

    async doRequests(data: ValidatedHSBData, project: Project) {
      this.progress.reset()
      this.progress.pending = true

      // make sure project context is loaded correctly
      const projectId = project.id
      await Promise.all([
        this.stores.operationalMode.ensureLoaded(projectId),
        this.stores[HSBGuiItemsEnum.conductorTypes].ensureLoadedByProject(projectId),
        this.stores[HSBGuiItemsEnum.systems].ensureLoaded(projectId),
        this.stores[HSBGuiItemsEnum.allocations].ensureLoaded(projectId),
        this.stores[HSBGuiItemsEnum.towerTypes].ensureLoadedByProject(projectId)
      ])

      const jobs: Promise<any>[] = []
      const trackProgress = async <T extends object>(
        job: Promise<T>,
        scope: keyof typeof this.progresses
      ) => {
        jobs.push(job)
        try {
          return await job
        } catch (e) {
          this.progress.errors.push(e as Error)
          this.progresses[scope].errors.push(e as Error)
          throw e
        } finally {
          this.progress.done++
          this.progresses[scope].done++
        }
      }
      const conductorTypes = Object.values(data.models.containers[HSBGuiItemsEnum.conductorTypes])
      const systems = Object.values(data.models.containers[HSBGuiItemsEnum.systems])
      const allocations = Object.values(data.models.containers[HSBGuiItemsEnum.allocations])
      const towerTypes = Object.values(data.models.containers[HSBGuiItemsEnum.towerTypes])
      const towers = Object.values(data.models.containers[HSBGuiItemsEnum.towers])
      const spans = Object.values(data.models.containers[HSBGuiItemsEnum.spans])
      const overheadLine = data.models.overheadLine

      this.progresses.hsbLibParameters.of = 1
      this.progresses[HSBGuiItemsEnum.conductorTypes].of = conductorTypes.length
      this.progresses[HSBGuiItemsEnum.systems].of = systems.length
      this.progresses[HSBGuiItemsEnum.allocations].of = allocations.length
      this.progresses[HSBGuiItemsEnum.towerTypes].of = towerTypes.length
      this.progresses[HSBGuiItemsEnum.towers].of = 1 // towers done in one request
      this.progresses[HSBGuiItemsEnum.spans].of = spans.length
      this.progresses[HSBGuiItemsEnum.spans].of = spans.length
      this.progresses[HSBGuiItemsEnum.overheadLine].of = 1
      this.progresses.operationalMode.of = 1

      this.progress.of = Object.values(this.progresses).reduce(
        (previousValue, currentValue) => previousValue + currentValue.of,
        0
      )

      try {
        // Name collisions
        this.handleNameCollisions(conductorTypes, systems, allocations, towerTypes, data)

        // HSBLibParameters
        trackProgress(
          this.stores.project.save({
            ...project,
            crs: data.crs,
            hsblibParameter: data.hsbLibParameter
          }),
          'hsbLibParameters'
        )

        // Conductor Types
        await Promise.all(
          conductorTypes.map(async (item) => {
            item.project = projectId
            return trackProgress(
              this.stores[HSBGuiItemsEnum.conductorTypes].save(item),
              HSBGuiItemsEnum.conductorTypes
            )
          })
        )

        // Systems
        await Promise.all(
          systems.map(async (item) => {
            return trackProgress(
              this.stores[HSBGuiItemsEnum.systems].save(item),
              HSBGuiItemsEnum.systems
            )
          })
        )

        // ConductorAllocations
        await Promise.all(
          allocations.map(async (item) => {
            return trackProgress(
              this.stores[HSBGuiItemsEnum.allocations].save(item),
              HSBGuiItemsEnum.allocations
            )
          })
        )

        // Overhead Line
        await trackProgress(
          this.stores[HSBGuiItemsEnum.overheadLine].save(overheadLine),
          HSBGuiItemsEnum.overheadLine
        )

        // wrap dependant requests into one async function
        const depending = async () => {
          // TowerTypes
          await Promise.all(
            towerTypes.map(async (item) => {
              return trackProgress(
                this.stores[HSBGuiItemsEnum.towerTypes].save(item),
                HSBGuiItemsEnum.towerTypes
              )
            })
          )

          // Towers
          await trackProgress(
            this.stores[HSBGuiItemsEnum.towers].towersReplaceAll({ list: towers }, overheadLine.id),
            HSBGuiItemsEnum.towers
          )

          // Spans - actually only update
          const overheadLineSpans = values(
            this.stores[HSBGuiItemsEnum.spans].findByIdOrFail(overheadLine.id).spansById
          )
          for (const span of overheadLineSpans) {
            const toUpdate = spans.find(
              (el) => el.beginTower === span.beginTower && el.endTower === span.endTower
            )
            if (!toUpdate) {
              this.progress.done++
              this.progress.errors.push(
                Error(
                  `Konnte Spannfeld ${span.id} nicht mit Werten aus dem Import updaten,
                  da Start- und/oder End-Masten nicht gefunden wurden
                  \n
                  beginTower: ${span.beginTower} \n
                  endTower: ${span.endTower} \n
                  `
                )
              )
              continue
            }
            trackProgress(
              this.stores[HSBGuiItemsEnum.spans].spanSave({ ...span, ...toUpdate, id: span.id }),
              HSBGuiItemsEnum.spans
            )
          }
        }
        await depending()

        // Operational Mode
        trackProgress(
          this.stores.operationalMode.save(data.models.operationalMode),
          'operationalMode'
        )

        await Promise.all(jobs)
      } catch (e) {
        console.error(e)
        this.errors.push(e as Error)
      } finally {
        this.progress.pending = false
      }
    },

    /**
     * Makes sure that after importing we do not have duplicate names
     */
    handleNameCollisions(
      conductorTypes: ConductorType[],
      systems: System[],
      allocations: ConductorAllocation[],
      towerTypes: TowerType[],
      data: ValidatedHSBData
    ) {
      conductorTypes.forEach((item) => {
        item.name = findUniqueCopyName(
          item.name,
          this.stores[HSBGuiItemsEnum.conductorTypes].projectItems.map((con) => con.name)
        )
      })
      systems.forEach((item) => {
        item.name = findUniqueCopyName(
          item.name,
          this.stores[HSBGuiItemsEnum.systems].items.map((sys) => sys.name)
        )
      })
      allocations.forEach((item) => {
        item.name = findUniqueCopyName(
          item.name,
          this.stores[HSBGuiItemsEnum.allocations].items.map((all) => all.name)
        )
      })

      towerTypes.forEach((item) => {
        item.name = findUniqueCopyName(
          item.name,
          this.stores[HSBGuiItemsEnum.towerTypes].projectItems.map((all) => all.name)
        )
      })
      data.models.operationalMode.name = findUniqueCopyName(
        data.models.operationalMode.name,
        useOperationalModeStore().items.map((op) => op.name)
      )
    }
  }
})
</script>

<template>
  <p-dialog
    class="!max-w-none"
    :show="modelValue"
    title="Freileitung importieren (HSBlib-OverheadLine-Format)"
    @close="doClose"
  >
    <div class="flex space-x-12 w-[80vw] max-w-[160ch]" style="height: calc(100vh - 18rem)">
      <div class="w-96">
        <p-form-section title="Datei auswählen">
          <template #actions>
            <a
              class="inline-block text-sm flex items-center justify-end text-gray-400 hover:text-gray-600"
              href="/import-templates/overheadLine_hsblib.json"
              target="_blank"
              download
            >
              <el-icon size="20" class="mr-2">
                <DownloadIcon />
              </el-icon>
              Beispiel herunterladen
            </a>
          </template>
          <FileInputResettable
            class="mb-8"
            accept=".json"
            label="Datei im Overhead-Line-Format auswählen"
            @change="onFileChange"
          />
        </p-form-section>
        <p-form-section title="Import-Optionen">
          <p-field v-model="systemNameSeparator" v-bind="separatorConfig"></p-field>
        </p-form-section>
      </div>
      <div class="flex-1 overflow-hidden">
        <div
          v-if="!dataParsed && errors.length === 0"
          class="bg-gray-100 flex items-center justify-center h-full"
        >
          <div class="text-gray-400 text-center">
            Wählen Sie zunächst links eine Datei aus, um hier eine Vorschau der zu importierenden
            Daten zu sehen.
          </div>
        </div>
        <el-scrollbar>
          <div v-if="errors.length" class="h-full">
            <ProjectImportErrorTabs :errors="errors"></ProjectImportErrorTabs>
          </div>
          <template v-else-if="dataParsed && dataValidated">
            <h2 class="text-lg font-bold text-primary-500 mb-4">Inhalte der Import-Datei:</h2>
            <el-collapse>
              <!-- overhead line -->
              <el-collapse-item name="overheadLine">
                <template #title>
                  <div class="flex-1">overheadLine</div>
                  <RequestsProgress
                    v-show="progresses.overheadLine.donePercent > 0"
                    :progress="progresses.overheadLine"
                    class="w-48 mr-4"
                  />
                </template>
                <!-- prettier-ignore -->
                <pre class="source">{{ dataValidated.models.overheadLine }}</pre>
              </el-collapse-item>

              <!-- models -->
              <el-collapse-item
                v-for="(data, containerName) in dataValidated.models.containers"
                :key="containerName"
                :name="containerName"
              >
                <template #title>
                  <div class="flex-1">{{ `${containerName} (${Object.values(data).length})` }}</div>
                  <RequestsProgress
                    v-show="progresses[containerName].donePercent > 0"
                    :progress="progresses[containerName]"
                    class="w-48 mr-4"
                  />
                </template>
                <pre class="source">{{ data }}</pre>
              </el-collapse-item>

              <!-- operationalMode -->
              <el-collapse-item name="operationalMode">
                <template #title>
                  <div class="flex-1">operationalMode</div>
                  <RequestsProgress
                    v-show="progresses.operationalMode.donePercent > 0"
                    :progress="progresses.operationalMode"
                    class="w-48 mr-4"
                  />
                </template>
                <!-- prettier-ignore -->
                <pre class="source">{{ dataValidated.models.operationalMode }}</pre>
              </el-collapse-item>

              <!-- hsbLibParameters -->
              <el-collapse-item name="parameters">
                <template #title>
                  <div class="flex-1">Projektdaten und HSBLib-Parameter</div>
                  <RequestsProgress
                    v-show="progresses.hsbLibParameters.donePercent > 0"
                    :progress="progresses.hsbLibParameters"
                    class="w-48 mr-4"
                  />
                </template>

                <div class="table-simple table-simple--striped overflow-auto mb-3">
                  <table style="width: 100%; pointer-events: none">
                    <caption>Projektdaten</caption>
                    <tbody>
                      <tr>
                        <td>CRS System</td>
                        <td>
                          <pre>{{ dataValidated.crs }}</pre>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Provide "Form" context -->
                <p-form :item="{ hsblibParameter: dataValidated.hsbLibParameter }">
                  <div class="table-simple table-simple--striped overflow-auto">
                    <table style="width: 100%; pointer-events: none">
                      <caption>HSBLib-Parameter</caption>
                      <thead>
                        <tr>
                          <th>Parameter</th>
                          <th class="w-20">HSBLib-Standard benutzen</th>
                          <th style="max-width: 150px">Benutzerdefinierter Wert</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ProjectHSBLibParameterFieldRow
                          v-for="(field, index) in fieldConfigHsbLibParameters"
                          :key="index"
                          :field-config="field"
                        />
                      </tbody>
                    </table>
                  </div>
                  <!-- remove save button -->
                  <template #buttons>&nbsp;</template>
                </p-form>
              </el-collapse-item>
            </el-collapse>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <template #footer>
      <RequestsProgress v-show="progress.donePercent > 0" :progress="progress" class="flex-1" />
      <p-btn
        :disabled="!dataParsed || errors.length > 0 || progress.donePercent === 100"
        :loading="progress.pending"
        type="primary"
        @click="handleImport"
      >
        Freileitung importieren
      </p-btn>
      <p-btn
        v-if="!progress.pending && progress.donePercent === 100 && errors.length === 0"
        type="primary"
        @click="doClose"
      >
        Fertig
      </p-btn>
    </template>
  </p-dialog>
</template>

<style scoped lang="css">
.source {
  @apply w-full overflow-x-auto bg-gray-50 leading-tight text-gray-500 p-2 pl-16;
}
</style>
