// @TODO: Use generated API types after refactoring to use generated types
import { HSBLibGlobals } from '@/config/schemas/hsb-lib/globals'

import {
  ConductorAllocation,
  ConductorType,
  MediaGroup,
  OperationalMode,
  PipeType,
  System,
  TowerType
} from '@/model'
import { OverheadLineExport } from '@/model/overhead-line'
import { HsbLibParameters, LogLevel, Project, ProjectRequest } from '@gridside/hsb-api'

export type ProjectId = Project['id']
export type { Project }
export { LogLevel as LogLevelEnum }

export enum CorridorModeEnum {
  FLAT = 'flat',
  ROUND = 'round'
}

/**
 * Because @gridside/hsb-api equivalent does (falsely) lower-case we use this type
 */
export type ProjectExport = Omit<ProjectRequest, 'hsblibParameter'> & {
  hsblibParameter: null | Partial<HsbLibParameters>
} & {
  data?: {
    ConductorAllocation?: ConductorAllocation[]
    ConductorType?: ConductorType[]
    MediaGroup?: MediaGroup[]
    OperationalMode?: OperationalMode[]
    OverheadLine?: OverheadLineExport[]
    PipeType?: PipeType[]
    System?: System[]
    TowerType?: TowerType[]
  }
}

export function fromHSBLibGlobals(globals: HSBLibGlobals): HsbLibParameters {
  return {
    corridorFlatend: globals.corridor_flatend ?? null,
    corridorFlatstart: globals.corridor_flatstart ?? null,
    corridorMode: globals.corridor_mode ?? null,
    displayPrint: globals.display_print ?? null,
    loggingTraceback: globals.logging_traceback ?? null,
    logLevel: null,
    flagInnerImpedanceBesselFunction: globals.FLAG_Inner_Impedance_Bessel_Function ?? null,
    flagDebugging: null,
    pipelineSegmentation: globals.PipelineSegmentation ?? null,
    relAbstol: globals.Rel_abstol ?? null,
    relAngletol: globals.Rel_angletol ?? null,
    relMaxAngle: globals.Rel_max_angle ?? null,
    relMinSegmentLength: globals.Rel_min_segment_length ?? null,
    relSimplifyMedium: globals.Rel_simplifyMedium ?? null,
    trimTillDecimalPoints: globals.Trim_till_decimal_points ?? null,
    utmReimportTolerance: globals.UTM_Reimport_Tolerance ?? null,
    utmTrimMode: globals.UTM_trim_mode ?? null
  }
}
