import { HsbApi } from '@/api'
import {
  MediaGroup,
  MediaGroupDraft,
  MediaGroupId,
  MediaGroupPipe,
  MediaGroupWire,
  ProjectId
} from '@/model'
import { Feature, FeatureCollection, MultiLineString } from 'geojson'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { LineString as LineStringOl, MultiLineString as MultiLineStringOl } from 'ol/geom'
import { MediaGroupBaseType, MediaGroupRequest } from '@gridside/hsb-api'
import { copy } from '@/util'
import { findUniqueCopyName } from '@/util/helpers'
import { Coordinate } from 'ol/coordinate'
import { lineSplit } from '@turf/turf'
import { FeaturePropEnum, FeatureTypeEnum } from '@/components/map/LayerItemConfig'
import { MediaBatchRequestProperties } from '@gridside/hsb-api/dist/models/MediaBatchRequestProperties'

export const useMediaGroupStore = defineStore('mediaGroup', {
  state: () => ({
    draft: null as MediaGroupDraft | null,
    drawing: false,
    itemsById: {} as Record<MediaGroupId, MediaGroup>,
    itemsToDelete: [] as MediaGroup[],
    loaded: false,
    loading: false,
    loadedProject: undefined as undefined | ProjectId,
    selection: [] as MediaGroupId[]
  }),

  getters: {
    findById(state) {
      return (id: MediaGroupId): MediaGroup | undefined => state.itemsById[id]
    },

    /**
     * Transforms all media groups to a single feature collection.
     */
    geoJSON(): FeatureCollection<MultiLineString> {
      const features: Feature<MultiLineString>[] = []
      const allItems = [...this.items]

      if (this.draft) {
        allItems.push(this.draft as MediaGroup)
      }

      allItems.forEach((mediaGroup) => {
        // ignore media groups without media
        // (would cause problems when reading features from GeoJSON)
        if (mediaGroup.mediaGeometry.coordinates.length === 0) {
          return
        }

        const feature: Feature<MultiLineString> = {
          id: mediaGroup.id,
          type: 'Feature',
          geometry: {
            type: 'MultiLineString',
            coordinates: mediaGroup.mediaGeometry.coordinates
          },
          properties: {
            [FeaturePropEnum.type]: FeatureTypeEnum.mediaGroup,
            [FeaturePropEnum.draft]: mediaGroup === this.draft ? true : undefined,
            name: mediaGroup.name,
            type: mediaGroup.type
          }
        }
        features.push(feature)
      })
      return {
        type: 'FeatureCollection',
        features
      }
    },

    items(): MediaGroup[] {
      return Object.values(this.itemsById)
    }
  },

  actions: {
    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    async load(projectId: ProjectId) {
      if (this.loadedProject !== projectId) {
        this.loaded = false
      }
      this.loading = true
      const itemsById: Record<MediaGroupId, MediaGroup> = {}
      this.itemsById = {}

      try {
        if (!projectId) {
          throw new Error('mediaGroupStore.load: ProjectId must not be empty.')
        }

        const mediaGroupsResponse = await HsbApi.mediaGroups.getMediaGroups(projectId)
        mediaGroupsResponse.results.forEach((item) => {
          itemsById[item.id] = item
        })
        this.itemsById = { ...itemsById }
        this.loadedProject = projectId
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    async saveBatch(
      projectId: string,
      mediaGroupIds: MediaGroupId[],
      changeset: MediaBatchRequestProperties
    ) {
      if (mediaGroupIds.length === 0) {
        throw Error('Mindestens eine Mediengruppe benötigt')
      }

      if (mediaGroupIds.length === 1) {
        const mediaGroup = this.findById(mediaGroupIds[0])
        if (!mediaGroup) {
          throw Error('Mediengruppe nicht vorhanden')
        }

        return [await this.save({ ...copy(mediaGroup), ...changeset })]
      }

      const allUpdatedMediaGroups: MediaGroup[] = []
      try {
        this.loading = true
        const response = await HsbApi.mediaGroups.batchUpdateMediaGroups(projectId, {
          items: mediaGroupIds,
          data: changeset
        })
        for (const updatedItem of response.results) {
          this.itemsById[updatedItem.id] = updatedItem
        }
        allUpdatedMediaGroups.push(...response.results)
      } finally {
        this.loading = false
      }
      return allUpdatedMediaGroups
    },

    async save(item: MediaGroup) {
      if (!item.id) {
        // item.mediaGeometry.coordinates = createMultiLineString(500, 10)
        item.id = uuid()
      }
      const response = await HsbApi.mediaGroups.saveMediaGroup(
        item.project,
        item.id,
        item as MediaGroupRequest
      )
      this.itemsById = { ...this.itemsById, [item.id]: response }
      this.draft = null
      return response
    },

    async delete(id: MediaGroupId) {
      const item = this.findById(id)
      if (item) {
        await HsbApi.mediaGroups.deleteMediaGroup(item.project, item.id)
        delete this.itemsById[id]
      }
    },

    /**
     * Merge multiple MediaGroups as one
     */
    async concatMediaGroups(ids: MediaGroupId[], base: MediaGroup): Promise<MediaGroup> {
      const deleteItems: MediaGroupId[] = []
      const newMediaGroup = copy(base)
      for (const id of ids) {
        if (newMediaGroup.id == id) {
          continue
        }
        const mediaGroup = this.itemsById[id]
        newMediaGroup.mediaGeometry.coordinates.push(...mediaGroup.mediaGeometry.coordinates)
        deleteItems.push(id)
      }

      const result = await this.save(newMediaGroup)
      deleteItems.forEach(this.delete)
      return result
    },
    isPipe(item?: MediaGroup | Partial<MediaGroup>): item is MediaGroupPipe {
      return item !== undefined && item.type === MediaGroupBaseType.PIPE
    },
    isWire(item?: MediaGroup): item is MediaGroupWire {
      return item !== undefined && item.type === MediaGroupBaseType.WIRE
    },

    async detachLineStringsFromMediaGroup(mediaGroupId: any, lineStringIndices: number[]) {
      const mediaGroup = this.findById(mediaGroupId)
      if (!mediaGroup) {
        throw Error('Mediengruppe nicht gefunden: ' + mediaGroupId)
      }
      const multilineString = new MultiLineStringOl(mediaGroup.mediaGeometry.coordinates)
      const lineStrings: LineStringOl[] = multilineString.getLineStrings()
      const detached = new MultiLineStringOl([])

      // extract line strings
      for (const lineStringIndex of lineStringIndices) {
        const lineString = lineStrings.splice(lineStringIndex, 1).pop()
        if (lineString) {
          detached.appendLineString(lineString)
        }
      }

      // save coordinates to existing media group
      const newOld = new MultiLineStringOl(lineStrings)
      mediaGroup.mediaGeometry.coordinates = newOld.getCoordinates()
      await this.save(mediaGroup)

      // save new media group
      const newDetached = copy(mediaGroup)
      newDetached.id = uuid()
      newDetached.mediaGeometry.coordinates = detached.getCoordinates()
      newDetached.name = findUniqueCopyName(
        newDetached.name,
        this.items.map((item) => item.name)
      )
      return await this.save(newDetached)
    },

    /**
     * Join LineStrings of a mediaGroup into one
     */
    async joinLineStrings(mediaGroupId: MediaGroupId, lineStringIndices: number[]) {
      const mediaGroup = copy(this.findById(mediaGroupId))
      if (!mediaGroup) {
        throw new Error(
          'useMediaGroupStore.joinLineStrings: There is no media-group with id ' + mediaGroupId
        )
      }
      function connectLineStrings(multiLineString: MultiLineStringOl, indices: number[]) {
        // Überprüfen der Gültigkeit der Indizes
        const lineStrings = indices.map((index) => {
          if (index >= 0 && index < multiLineString.getLineStrings().length) {
            return multiLineString.getLineString(index)
          } else {
            throw new Error('Index out of bounds')
          }
        })

        // Initialisiere ein Array für die verbundenen Koordinaten
        let connectedCoordinates: Coordinate[] = []

        // Durchlaufe alle ausgewählten LineStrings
        for (let i = 0; i < lineStrings.length - 1; i++) {
          // Hole die Koordinaten der aktuellen und der nächsten LineString
          const coords1 = lineStrings[i].getCoordinates()
          const coords2 = lineStrings[i + 1].getCoordinates()

          // Berechne alle möglichen Kombinationen von Endpunkten zwischen zwei LineStrings
          const combinations = [
            { start: coords1[0], end: coords2[0] },
            { start: coords1[0], end: coords2[coords2.length - 1] },
            { start: coords1[coords1.length - 1], end: coords2[0] },
            { start: coords1[coords1.length - 1], end: coords2[coords2.length - 1] }
          ]

          // Berechne die Distanzen für alle Endpunkt-Kombinationen
          const distances = combinations.map((pair) =>
            new LineStringOl([pair.start, pair.end]).getLength()
          )

          // Finde die Kombination mit der geringsten Distanz
          const minDistanceIndex = distances.indexOf(Math.min(...distances))
          const closestPair = combinations[minDistanceIndex]

          // Kehre die Koordinaten um, wenn nötig, um die nächstgelegenen Enden zu verbinden
          if (closestPair.start === coords1[0]) {
            coords1.reverse()
          }
          if (closestPair.end === coords2[coords2.length - 1]) {
            coords2.reverse()
          }

          // Füge die Koordinaten des ersten LineStrings hinzu, wenn es der erste Durchlauf ist
          if (i === 0) {
            connectedCoordinates = connectedCoordinates.concat(coords1)
          }

          // Füge die Koordinaten des zweiten LineStrings hinzu, ohne den ersten Punkt zu duplizieren
          connectedCoordinates = connectedCoordinates.concat(coords2.slice())
        }

        // Erstelle einen neuen MultiLineString mit den verbundenen Koordinaten
        return new MultiLineStringOl([connectedCoordinates])
      }

      const linesOld = mediaGroup.mediaGeometry.coordinates
      const linesConnected = connectLineStrings(new MultiLineStringOl(linesOld), lineStringIndices)
      const linesKeep = linesOld.filter((_, index) => !lineStringIndices.includes(index))

      mediaGroup.mediaGeometry.coordinates = linesKeep.concat(linesConnected.getCoordinates())
      await this.save(mediaGroup)
    },

    /**
     * Split a media-group into two at a given point
     */
    async split(mediaGroupId: MediaGroupId, splitLineStringIndex: number, splitAt: Coordinate) {
      const mediaGroup = copy(this.itemsById[mediaGroupId])
      if (!mediaGroup) {
        throw new Error('useMediaGroupStore.split: There is no media-group with id ' + mediaGroupId)
      }

      // get the closest point from cursor to line
      const lineString = new LineStringOl(
        mediaGroup.mediaGeometry.coordinates[splitLineStringIndex]
      )
      const splitClosest = lineString.getClosestPoint(splitAt)

      // Let TurfJS do the splitting math
      const splitFeatures = lineSplit(
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: mediaGroup.mediaGeometry.coordinates[splitLineStringIndex]
          },
          properties: {}
        },
        { type: 'Feature', geometry: { type: 'Point', coordinates: splitClosest }, properties: {} }
      )

      mediaGroup.mediaGeometry.coordinates[splitLineStringIndex] =
        splitFeatures.features[0].geometry.coordinates
      mediaGroup.mediaGeometry.coordinates.push(splitFeatures.features[1].geometry.coordinates)

      // send to server
      return await this.save(mediaGroup)
    }
  }
})
