import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { prependColon, RouteParams } from '@/router/routeParams'
import { RouteRecordRaw } from 'vue-router'

const mapRoutes: RouteRecordRaw = {
  path: 'map',
  component: () => import('@/layouts/pages/SplitView.vue'),
  props: {
    overlay: true,
    maxWidth: '38rem',
    class: 'splitview'
  },
  beforeEnter(to, from, next) {
    const entityParams = [RouteParams.OverheadLineId, RouteParams.TowerId, RouteParams.SpanId]
    const routeParams = Object.keys(to.params)

    // Enable edit mode if an entity parameter was given in the URL
    for (const param of entityParams) {
      if (routeParams.includes(param)) {
        const mapMode = useMapMode()
        mapMode.value = MapMode.EDIT
        break
      }
    }
    next()
  },
  children: [
    {
      path: '',
      name: 'project-map',
      component: () => import('@/views/project/MapView.vue'),
      beforeEnter() {
        const { clear, selectedFeatures } = useMapSelection()
        if (selectedFeatures.value.length > 0) {
          clear()
        }
      }
    },
    {
      path: 'overheadLine/create',
      name: 'project-map-overheadLine-create',
      meta: {
        create: true,
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/overhead-lines/OverheadLineForm.vue')
      }
    },
    {
      path: 'overheadLine/' + prependColon(RouteParams.OverheadLineId),
      name: 'project-map-overheadLine-edit',
      meta: {
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/overhead-lines/OverheadLineForm.vue')
      }
    },
    {
      path: 'tower/create',
      name: 'project-map-tower-create',
      meta: {
        create: true,
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/towers/TowerDetail.vue')
      }
    },
    {
      path: 'tower/' + prependColon(RouteParams.TowerId),
      name: 'project-map-tower-edit',
      meta: {
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/towers/TowerDetail.vue')
      }
    },
    {
      path: 'span/' + prependColon(RouteParams.SpanId),
      name: 'project-map-span-edit',
      meta: {
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/spans/SpanDetail.vue')
      }
    },
    {
      path: 'system/' + prependColon(RouteParams.SystemId),
      name: 'project-map-system-edit',
      meta: {
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/systems/SystemForm.vue')
      }
    },
    {
      path: 'media-group/create',
      name: 'project-map-media-group-create',
      meta: {
        create: true,
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/media-groups/MediaGroupDetail.vue')
      }
    },
    {
      path: 'media-group/' + prependColon(RouteParams.MediaGroupId),
      name: 'project-map-media-group-edit',
      meta: {
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/media-groups/MediaGroupDetail.vue')
      }
    },
    {
      path: 'operational-mode/' + prependColon(RouteParams.OperationalModeId),
      name: 'project-map-operationalmode-edit',
      meta: {
        returnRoute: { name: 'project-map' }
      },
      components: {
        default: () => import('@/views/project/MapView.vue'),
        detail: () => import('@/views/project/operational-modes/OperationalModeDetail.vue')
      }
    }
  ]
}

export default mapRoutes
