import { WebSocket } from '@/api'
import {
  CalculationExtendedApi,
  ConductorTypesExtendedApi,
  OperationalModesExtendedApi,
  PipeTypesExtendedApi,
  ProjectsExtendedApi,
  ResultExtendedApi,
  TowerTypesExtendedApi,
  UsersExtendedApi
} from '@/api/extended'

import {
  ConductorAllocationsApi,
  Configuration,
  HealthApi,
  MediaGroupsApi,
  OverheadLinesApi,
  SpansApi,
  SystemsApi,
  TowersApi
} from '@gridside/hsb-api'

export class HsbApiBase {
  readonly calculation: CalculationExtendedApi
  readonly conductorAllocations: ConductorAllocationsApi
  readonly conductorTypes: ConductorTypesExtendedApi
  readonly health: HealthApi
  readonly mediaGroups: MediaGroupsApi
  readonly operationalModes: OperationalModesExtendedApi
  readonly overheadLines: OverheadLinesApi
  readonly pipeTypes: PipeTypesExtendedApi
  readonly projects: ProjectsExtendedApi
  readonly result: ResultExtendedApi
  readonly spans: SpansApi
  readonly systems: SystemsApi
  readonly towers: TowersApi
  readonly towerTypes: TowerTypesExtendedApi
  readonly users: UsersExtendedApi

  constructor(config: Configuration, webSocket: WebSocket) {
    this.calculation = new CalculationExtendedApi(config, webSocket)
    this.conductorAllocations = new ConductorAllocationsApi(config)
    this.conductorTypes = new ConductorTypesExtendedApi(config, webSocket)
    this.health = new HealthApi(config)
    this.mediaGroups = new MediaGroupsApi(config)
    this.operationalModes = new OperationalModesExtendedApi(config)
    this.overheadLines = new OverheadLinesApi(config)
    this.projects = new ProjectsExtendedApi(config, webSocket)
    this.pipeTypes = new PipeTypesExtendedApi(config, webSocket)
    this.result = new ResultExtendedApi(webSocket)
    this.spans = new SpansApi(config)
    this.systems = new SystemsApi(config)
    this.towers = new TowersApi(config)
    this.towerTypes = new TowerTypesExtendedApi(config, webSocket)
    this.users = new UsersExtendedApi(config, webSocket)
  }
}
