<script lang="ts">
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { OperationalMode, ProjectId } from '@/model'
import OperationalModeForm from '@/views/project/operational-modes/OperationalModeForm.vue'
import { ElMessage } from 'element-plus'
import { RouteLocationRaw } from 'vue-router'
import { RouteParams } from '@/router/routeParams'

export default defineComponent({
  name: 'OperationalModeDetail.vue',
  components: { OperationalModeForm, DetailPanel },

  setup() {
    const store = useOperationalModeStore()
    return { store }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    create(): boolean {
      return !this.$route.params[RouteParams.OperationalModeId]
    },
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },

    id() {
      return this.$route.params.id as string
    },

    selectedItems(): OperationalMode[] {
      const item = this.store.findById(this.id)
      return this.store.loaded && !this.create && item ? [item] : []
    },

    title(): string {
      if (this.create) {
        return 'Neuer Betriebsfall'
      } else {
        return 'Betriebsfall bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-operational-modes' }
      )
    },
    async onSave(item: OperationalMode) {
      this.saving = true
      try {
        await this.store.save({ ...item, project: this.projectId })

        ElMessage.success(
          this.create
            ? 'Betriebsfall wurde erfolgreich angelegt.'
            : 'Daten wurden erfolgreich gespeichert.'
        )
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="onClose">
    <OperationalModeForm :saving="saving" @cancel="onClose" @submit="onSave" />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
