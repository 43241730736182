<script lang="ts">
import { defineComponent } from 'vue'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { ConductorState, OperationalMode, OperationalModeId, System } from '@/model'
import { useSystemStore } from '@/stores/system'
import deepmerge from 'deepmerge'
import { operationalMode } from '@/config/fields'
import { SelectItem } from '@prionect/ui'
import { useProject } from '@/composables/useProject'

/**
 * Helper function for array handling in deepmerge
 */
function arrayMerge(a: any[], b: any[]) {
  const count = Math.max(a.length, b.length)
  const result = []
  for (let i = 0; i < count; i++) {
    result[i] = b[i] || a[i] || undefined
  }
  // additional deepmerge to ensure we lose references to the original objects
  return deepmerge([], result)
}

export default defineComponent({
  name: 'OperationalModeForm',

  data: () => ({
    activeTab: 0,
    fieldConfig: operationalMode
  }),

  computed: {
    create(): boolean {
      return !this.id
    },

    defaultItem(): Partial<OperationalMode> {
      const conductorStates: OperationalMode['conductorStates'] = {}
      this.systems.forEach((system) => {
        conductorStates[system.id] = []
        for (let i = 1; i <= system.wireCount; i++) {
          conductorStates[system.id].push({} as ConductorState)
        }
      })
      return {
        type: 'normal',
        conductorStates
      }
    },

    formItem(): Partial<OperationalMode> {
      return this.store.loaded && !this.create
        ? deepmerge(this.defaultItem, this.store.findById(this.id) || {}, { arrayMerge })
        : this.defaultItem
    },

    id(): OperationalModeId {
      return this.$route.params.id as string
    },

    systems(): System[] {
      return this.systemStore.items
    },

    typeItems(): SelectItem[] {
      return ['normal', 'failure'].map((type) => ({
        value: type,
        label: this.$t(`operationalMode.type.${type}`)
      }))
    }
  },

  setup() {
    const store = useOperationalModeStore()
    const systemStore = useSystemStore()
    const { projectId } = useProject()
    return { store, systemStore, projectId }
  },

  mounted() {
    this.store.ensureLoaded(this.projectId)
    this.systemStore.ensureLoaded(this.projectId)
  },
  methods: {
    asOpMode(item: any): OperationalMode {
      return item
    }
  }
})
</script>

<template>
  <p-form :item="formItem" v-slot="{ values }" v-if="store.loaded">
    <p-field v-bind="fieldConfig.name" />
    <p-field v-bind="fieldConfig.type" />
    <p-field v-if="asOpMode(values).type === 'failure'" v-bind="fieldConfig.expectationFactor" />
    <div class="flex space-x-6 w-full">
      <p-field v-bind="fieldConfig.limitVoltage" class="flex-1" />
      <p-field v-bind="fieldConfig.frequency" class="flex-1" />
    </div>

    <p-form-section
      v-if="systems.length > 0"
      title="Leiterzustände je System"
      class="border-none"
    ></p-form-section>

    <p-tabs :tabs="systems.map((s) => ({ id: s.id, label: s.name }))">
      <template v-for="system in systems" :key="system.id" v-slot:[`tab:${system.id}`]>
        <div class="grid grid-cols-[2em_repeat(2,_1fr)] gap-x-6 gap-y-2 pr-1">
          <template v-for="i in system.wireCount" :key="i">
            <div
              class="text-center text-sm font-semibold text-gray-500"
              :class="i === 1 ? 'pt-[34px]' : 'pt-2.5'"
            >
              L{{ i }}
            </div>
            <p-field
              v-bind="fieldConfig.current"
              :name="`conductorStates.${system.id}.${i - 1}.current`"
              :label="i === 1 ? fieldConfig.current.label || '' : ''"
              dense
              right
            />
            <p-field
              v-bind="fieldConfig.angle"
              :name="`conductorStates.${system.id}.${i - 1}.angle`"
              :label="i === 1 ? fieldConfig.angle.label || '' : ''"
              dense
              right
            />
          </template>
        </div>
      </template>
    </p-tabs>
  </p-form>
</template>
